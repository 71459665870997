import { db } from '@/plugins/firebase'
import { collection, addDoc, serverTimestamp } from "firebase/firestore"

const axios = require('axios').default;

function getStrLen (str, numChars, lower = false) {
  if (lower) {
    return str.substring(0,numChars).toLowerCase()
  } else {
    return str.substring(0,numChars)
  }

}

async function imprimirComprobante(dataComprobante, formaPago, photoUrl, comprobanteId, user, tipoComprobante) {
  let arrayItemns = []
  let subtotalIva0 = 0
  let subtotalIva12 = 0
  dataComprobante.items.forEach(item => {
    arrayItemns.push({
      "descripcion": getStrLen(item.descripcion, 15, true),
      "cantidad": item.cantidad.toString(),
      "precio": item.precioUnitario.toString(),
      "descuento": item.descuento.toString(),
      "subtotal": item.precioTotalSinImpuesto.toString(),
    })
  })
  dataComprobante.totalImpuesto.forEach(impuesto => {
    if (impuesto.codigoPorcentaje === 0) {
      subtotalIva0+=impuesto.baseImponible
    }
    if (impuesto.codigoPorcentaje === 2) {
      subtotalIva12+=impuesto.baseImponible
    }
  })
  const dataPrint = {
    "negocio": {
      "logo": photoUrl,
      "nombre": getStrLen(dataComprobante.nombreComercial, 35),
      "matriz": dataComprobante.matriz === 'SI',
      "ruc": dataComprobante.ruc,
      "direccionMatriz": getStrLen(dataComprobante.direccionMatriz, 22),
      "direccionEstablecimiento": getStrLen(dataComprobante.direccionEstablecimiento, 22),
      "telefono": dataComprobante.telefono,
      "contabilidad": dataComprobante.obligadoContabilidad,
      "contribuyenteEspecial": dataComprobante.contribuyenteEspecial,
      "nroResolucionRetencion": dataComprobante.nroResolucionRetencion,
      "regimenRimpe": dataComprobante.regimenRimpe,
      "mensaje": dataComprobante.mensajeTicket
    },
    "cliente": {
      "numeroDocumento": dataComprobante.cliente.numeroDocumento,
      "nombre":  getStrLen(dataComprobante.cliente.nombre, 22),
      "telefono": dataComprobante.cliente.telefono,
      "email": dataComprobante.cliente.email,
      "direccion": getStrLen(dataComprobante.cliente.direccion, 22)
    },
    "comprobante": {
      "tipo": tipoComprobante, /*******  FACTURA O RECIBO *************/
      "numeroAutorizacion": dataComprobante.claveAcceso ? dataComprobante.claveAcceso : '',
      "numeroComprobante": dataComprobante.numeroComprobante,
      "fecha": dataComprobante.fechaEmision,
      "hora": dataComprobante.horaEmision,
      "subtotal": dataComprobante.subtotal.toFixed(2).toString(),
      "subtotalIva0": subtotalIva0.toFixed(2).toString(),
      "subtotalIva12": subtotalIva12.toFixed(2).toString(),
      "impuestos": dataComprobante.impuestos.toFixed(2).toString(),
      "descuento": dataComprobante.totalDescuento.toFixed(2).toString(),
      "total": dataComprobante.total.toFixed(2).toString(),
      "formaPago": formaPago,
      "cajero": user.userName,
      "ambiente": dataComprobante.ambiente === 1 ? 'PRUEBAS' : 'PRODUCCION',
      "tipoEmision" : 'NORMAL' /******* TIPO DE EMISIÓN *************/
    },
    "items": arrayItemns
  }
  dataPrint.id = comprobanteId.substring(comprobanteId.length - 4)
  const service = tipoComprobante  === 'RECIBO' ? 'imprimirRecibo' : 'imprimirRide'
  setPrint(
    user.userId,
    'PagoComprobante',
    'Nuevo Pago',
    service,
    dataPrint
  )
}

async function setPrint (userId, origin, title, service, dataPost) {
  try {
    // console.log('CREATE DATA PRINTS -> ', userId, origin, title, service, dataPost)
    if (userId && title ) {
      await addDoc(collection(db, "prints"), {
        origin,
        title,
        service,
        dataPost,
        state: 'Nueva',
        deleted: false,
        userId,
        updated: new serverTimestamp,
        date: new serverTimestamp
      })
      .then((doc) => {
        return doc
      })
    } else {
      throw 'No hay datos para procesar la impresión'
    }
  } catch (er) {
    console.error('error: -> ', er)
    // throw 'Error al generar la impresion'
  }
}

async function printDocument (service, data, puerto = '5000') {
  try {
    let	servicio = 'http://localhost:' + puerto + '/' + service
    const dataComanda = JSON.stringify(data).toString()
    const dataS = dataComanda.replaceAll('"',"'")
    var config = {
      method: 'post',
      url: servicio,
      headers: { 
        'Content-Type': 'application/json',
      },
      data : dataS
    };
    await axios(config)
    .then((result)=> {
      console.log('IMPRESIÓN - ', result)
    })
    // .catch((error) => {
    //   console.error('elERROR - ', error)
    // })
  } catch (er) {
    console.error('error: -> ', er)
  }
}
export default { setPrint, printDocument, imprimirComprobante }
