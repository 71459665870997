export const dataGruposDrawer = [
  {
    "descripcion": "Ventas",
    "grupo": "ventas",
    "icon": "fa-cash-register",
    "order": 1
  },
  {
    "descripcion": "Inventario",
    "grupo": "inventario",
    "icon": "fa-box",
    "order": 2
  },
  {
    "descripcion": "CRM",
    "grupo": "crm",
    "icon": "fa-person-rays",
    "order": 3
  },
  {
    "descripcion": "Cajas",
    "grupo": "caja",
    "icon": "fa-sack-dollar",
    "order": 4
  },
  {
    "descripcion": "Bancos",
    "grupo": "bancos",
    "icon": "fa-vault",
    "order": 5
  },
  {
    "descripcion": "Usuarios",
    "grupo": "usuarios",
    "icon": "fa-solid fa-users-gear",
    "order": 6
  },
  {
    "descripcion": "Configuracion",
    "grupo": "configuracion",
    "icon": "fa-solid fa-gears",
    "order": 7
  },
  {
    "descripcion": "Catálogo",
    "grupo": "catalogo",
    "icon": "fa-store",
    "order": 8
  },
  {
    "descripcion": "Reportes",
    "grupo": "reportes",
    "icon": "fa-solid fa-database",
    "order": 9
  }
]
